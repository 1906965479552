import * as moment from 'moment';
import {Moment} from 'moment';
import Hours from '../models/Hours';

import 'moment/locale/de';
moment().locale('de');

export class TimeUtils {
	static doesHoursMatch(mom: Moment, schedule: Hours[]): boolean {
		if (!schedule || schedule.length === 0) {
			return true;
		}
		const weekday = mom.weekday();
		for (const hour of schedule) {
			if (hour.weekday !== weekday) {
				continue;
			}
			const hours = TimeUtils.hoursToSchedule(hour);
			if (mom.isBetween(hours.openedAt, hours.closedAt)) {
				return true;
			}
		}
		return false;
	}

	static doesHoursMatchNow(schedule: Hours[]): boolean {
		return TimeUtils.doesHoursMatch(moment().seconds(0).milliseconds(0), schedule);
	}

	/**
	 * Converts hours to LocalTime and puts Hours together if they are one after the other
	 * @param hours Hours to sanitize
	 */
	static sanitizeHours(hours: Hours[]): Hours[] {
		if (!hours || hours.length === 0) {
			return [];
		}
		const result: Hours[] = [];
		for (const hour of hours) {
			const schedule = TimeUtils.hoursToSchedule(hour, false);
			const localHour = new Hours();
			localHour.weekday = hour.weekday;
			localHour.openedAt = schedule.openedAt.format('HH:mm');
			localHour.closedAt = schedule.closedAt.format('HH:mm');
			result.push(localHour);
			if (localHour.openedAt === '00:00') {
				const prevDay = localHour.weekday === 0 ? 6 : localHour.weekday - 1;
				const prev = result.find(prevHour => prevHour.weekday === prevDay && prevHour.closedAt === '23:59');
				if (prev) {
					prev.closedAt = localHour.closedAt;
					prev.connectedWithNext = true;
					localHour.connectedWithPrev = true;
				}
			}
		}
		const first = result[0];
		if (first.openedAt === '00:00') {
			const prevDay = first.weekday === 0 ? 6 : first.weekday - 1;
			const prev = result.find(prevHour => prevHour.weekday === prevDay && prevHour.closedAt === '23:59');
			if (prev) {
				prev.closedAt = first.closedAt;
				prev.connectedWithNext = true;
				first.connectedWithPrev = true;
			}
		}
		return TimeUtils.sortHours(result);
	}

	static sortHours(hours: Hours[]): Hours[] {
		return hours.sort((a, b) => {
			if (a.weekday < b.weekday) {
				return -1;
			}
			if (a.weekday > b.weekday) {
				return 1;
			}
			if (a.weekday === b.weekday) {
				const openAtA = +a.openedAt.replace(':', '');
				const openAtB = +b.openedAt.replace(':', '');
				if (openAtA < openAtB) {
					return -1;
				}
				if (openAtA > openAtB) {
					return 1;
				}
				return 0;
			}
		});
	}

	static hoursToSchedule(
		hour: Hours,
		localTime: boolean = true
	): {
		openedAt: Moment;
		closedAt: Moment;
	} | null {
		if (!hour) {
			return null;
		}
		const now = moment().second(0).millisecond(0);
		const openedAtTokens = hour.openedAt.split(':').map(token => Number(token));
		const closedAtTokens = hour.closedAt.split(':').map(token => Number(token));
		let openedAt = this.tokensToMoment(now, openedAtTokens, localTime);
		let closedAt = this.tokensToMoment(now, closedAtTokens, localTime);
		if (hour.connectedWithNext || closedAt.isBefore(openedAt)) {
			closedAt = closedAt.add(1, 'days');
		}
		const nowWeekday = now.weekday();
		const daysToAdd = hour.weekday < nowWeekday ? 7 - (nowWeekday - hour.weekday) : hour.weekday - nowWeekday;
		openedAt = openedAt.add(daysToAdd, 'days');
		closedAt = closedAt.add(daysToAdd, 'days');
		return {
			openedAt,
			closedAt
		};
	}

	static tokensToMoment(now: Moment, tokens: number[], localTime: boolean): Moment {
		let mom = moment();
		if (!localTime) {
			mom = mom.utc();
		}
		mom = mom.hour(tokens[0]).minute(tokens[1]).second(0).millisecond(0);
		if (!localTime) {
			mom = mom.local().date(now.date()).month(now.month()).year(now.year());
			if (!mom.isDST()) {
				mom = mom.add(1, 'hour');
			}
		}
		return mom;
	}

	static getSchedulesOfDay(mom: Moment, schedule: Hours[]): Hours[] {
		return schedule.filter(hour => {
			return mom.weekday() === hour.weekday;
		});
	}

	static isSameDate(m1: Moment, m2: Moment): boolean {
		if (m1 === m2) {
			return true;
		}
		if (!m1 || !m2) {
			return false;
		}
		const moment1 = moment(m1);
		const moment2 = moment(m2);
		return moment1.dayOfYear() === moment2.dayOfYear() && moment1.year() === moment2.year();
	}
}
