import {Component} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-allergen',
	templateUrl: './allergen.page.html',
	styleUrls: ['allergen.page.scss']
})
export class AllergenPage {
	static url = 'allergen';
	constructor(
		public repository: RepositoryService,
		private router: Router,
		public transService: TranslateService
	) {}
	public additives = {
		'1': this.transService.instant('additives.1'),
		'2': this.transService.instant('additives.2'),
		'3': this.transService.instant('additives.3'),
		'4': this.transService.instant('additives.4'),
		'5': this.transService.instant('additives.5'),
		'6': this.transService.instant('additives.6'),
		'7': this.transService.instant('additives.7'),
		'8': this.transService.instant('additives.8'),
		'9': this.transService.instant('additives.9'),
		'10': this.transService.instant('additives.10'),
		'11': this.transService.instant('additives.11'),
		'12': this.transService.instant('additives.12')
	};

	public allergenes = {
		allergenes: this.transService.instant('additives.allergenes'),
		cerealsWithGluten: this.transService.instant('additives.cerealsWithGluten'),
		crustaceans: this.transService.instant('additives.crustaceans'),
		eggs: this.transService.instant('additives.eggs'),
		fish: this.transService.instant('additives.fish'),
		peanuts: this.transService.instant('additives.peanuts'),
		soya: this.transService.instant('additives.soya'),
		milk: this.transService.instant('additives.milk'),
		nuts: this.transService.instant('additives.nuts'),
		celery: this.transService.instant('additives.celery'),
		mustard: this.transService.instant('additives.mustard'),
		sesame: this.transService.instant('additives.sesame'),
		sulfur: this.transService.instant('additives.sulfur'),
		lupins: this.transService.instant('additives.lupins'),
		molluscs: this.transService.instant('additives.molluscs'),
		corn: this.transService.instant('additives.corn'),
		wheat: this.transService.instant('additives.wheat'),
		rye: this.transService.instant('additives.rye'),
		barley: this.transService.instant('additives.barley'),
		oats: this.transService.instant('additives.oats'),
		spelt: this.transService.instant('additives.spelt'),
		khorasan: this.transService.instant('additives.khorasan'),
		walnuts: this.transService.instant('additives.walnuts'),
		pecanNuts: this.transService.instant('additives.pecanNuts'),
		brazilNuts: this.transService.instant('additives.brazilNuts'),
		pistachioNuts: this.transService.instant('additives.pistachioNuts'),
		macadamiaNuts: this.transService.instant('additives.macadamiaNuts'),
		almonds: this.transService.instant('additives.almonds'),
		hazelnuts: this.transService.instant('additives.hazelnuts'),
		cashews: this.transService.instant('additives.cashews'),
		seeds: this.transService.instant('additives.seeds'),
		poppySeeds: this.transService.instant('additives.poppySeeds'),
		sunflowerSeeds: this.transService.instant('additives.sunflowerSeeds'),
		poultryMeat: this.transService.instant('additives.poultryMeat'),
		beef: this.transService.instant('additives.beef'),
		pigmeat: this.transService.instant('additives.pigmeat')
	};

	get keysOfAdditives() {
		return Object.keys(this.additives);
	}

	get keysOfAllergenes() {
		return Object.keys(this.allergenes);
	}

	static navigate(router: Router) {
		router.navigateByUrl(AllergenPage.url);
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
