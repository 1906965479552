import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const icelandCustomerGroup = 'iceland';
export const customerGroup = icelandCustomerGroup;

const supportEmail = {
	iceland: 'iceland@smoothr.de'
};
const firebaseConfig = {
	iceland: {
		apiKey: 'AIzaSyBx3rlbR5y81d9sWT4DJEfDlqFyqUmXaz0',
		authDomain: 'dev-env-216009.firebaseapp.com',
		databaseURL: 'https://dev-env-216009.firebaseio.com',
		projectId: 'dev-env-216009',
		storageBucket: 'dev-env-216009.appspot.com',
		messagingSenderId: '1004449889395',
		appId: '1:1004449889395:web:90be71c815a260aae0fe06',
		measurementId: 'G-5Y133MQW9J'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyASyhGnKtZa_Id2l-Gp9_vWIDDIIvlK2OE',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de'],
	hasRecommendations: false
};

import 'zone.js/dist/zone-error';
