import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {HomePage} from './home.page';
import {SharedModuleModule} from '../../shared-module/shared-module.module';
import {AddToHomeComponent} from '../../components/add-to-home/add-to-home.component';
import {HomePreorderTypeButtonComponent} from '../../components/home-preorder-type-button/home-preorder-type-button.component';
import {HomePageSlidesComponent} from '../../components/home-page-slides/home-page-slides.component';
import {ScanBoardingPassComponent} from 'src/app/components/scan-boarding-pass/scan-boarding-pass.component';
import {ZXingScannerModule} from 'src/scanner/public_api';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		RouterModule.forChild([
			{
				path: '',
				component: HomePage
			}
		]),
		SharedModuleModule,
		ZXingScannerModule,
		ReactiveFormsModule
	],
	declarations: [
		HomePage,
		AddToHomeComponent,
		HomePreorderTypeButtonComponent,
		HomePageSlidesComponent,
		ScanBoardingPassComponent
	]
})
export class HomePageModule {}
