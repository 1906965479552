import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-add-custom-tip',
	templateUrl: './add-custom-tip.component.html',
	styleUrls: ['./add-custom-tip.component.scss']
})
export class AddCustomTipComponent {
	customTip = '';
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController, customTip: string): Promise<number> {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: AddCustomTipComponent,
			componentProps: {
				customTip
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}

	async dismiss() {
		this.modalCtrl.dismiss();
	}
	async addCustomTip() {
		console.log(this.customTip);
		await this.modalCtrl.dismiss(this.customTip);
	}
	numberOnlyValidation(event: any) {
		const pattern = /[0-9,]/;
		let inputChar = String.fromCharCode(event.charCode);
		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
		const inputValue = event.target.value;
		if (inputValue && inputValue.includes(',')) {
			const lengthAfterComma = inputValue.split(',')[1].length;
			if (lengthAfterComma >= 2) {
				event.preventDefault();
			}
		}
	}
}
