import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectLanguageModalComponent} from '../select-language-modal/select-language-modal.component';
import {ModalController} from '@ionic/angular';
import {HomePage} from 'src/app/pages/home/home.page';
import {Router} from '@angular/router';

@Component({
	selector: 'app-header-with-language-switcher',
	templateUrl: './header-with-language-switcher.component.html',
	styleUrls: ['./header-with-language-switcher.component.scss']
})
export class HeaderWithLanguageSwitcherComponent implements OnInit {
	@Input() logo = false;
	@Input() backButton = false;
	@Input() burgerButton = false;
	@Input() crossButton = false;
	@Input() title = '';

	@Output() backClick = new EventEmitter<void>();
	@Output() close = new EventEmitter<void>();

	constructor(
		public translate: TranslateService,
		private modalCtrl: ModalController,
		private router: Router
	) {}

	ngOnInit() {}

	async selectLanguageModal() {
		await SelectLanguageModalComponent.show(this.modalCtrl);
	}

	goToHome() {
		HomePage.navigate(this.router);
	}
}
